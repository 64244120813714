@import './mui-variable.scss';

.ViewNotFund {
  padding: $theme-spacing-4;
}

.ViewNotFundContent {
  padding-top: 150px;
  text-align: center;
}

.ViewNotFundImage {
  margin-top: $theme-spacing-2;
  display: inline-block;
  max-width: 100%;
  width: 560px;
}

.DealListDetail {
  width: 100%;
}

.DealListDetailPaper {
  width: 100%;
  margin-bottom: $theme-spacing-2;
  padding: 50px;
}

.DealListDetailBackdrop {
  z-index: $theme-zIndex-drawer + 1;
  color: #fff;
}

.DealList {
  width: 100%;
}

.DealListPaper {
  width: 100%;
  margin-bottom: $theme-spacing-2;
}

.DealListTable {
  min-width: 750px;
}

.DealListShineRow {
  &:hover {
    background-color: #DDDDDD !important;
  }
  background: #3dabe248;
}

.DealListWarnRow {
  &:hover {
    background-color: #DDDDDD !important;
  }
  background: #FFD0D0;
}

.DealListCalmRow {
  &:hover {
    background-color: #DDDDDD !important;
  }
}

.DealListCalmRowSelected {
  background-color: #DDDDDD !important;
}

.DealListVisuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
}

.DealListSearchInput {
  margin-right: $theme-spacing-1;
}

.DealListBackdrop {
  z-index: $theme-zIndex-drawer + 1;
  color: #fff;
}

.DealListToolbar {
  padding-left: $theme-spacing-2;
  padding-right: $theme-spacing-1;
}

.DealListToolbarTitle {
  flex: 1 1 100%;
}

.Audit {
  width: 100%;
}
.AuditPaper {
  width: 100%;
  margin-bottom: $theme-spacing-2;
}
.AuditTable {
  min-width: 750px;
}

.IntReport {
  width: 100%;
}
.IntReportPaper {
  width: 100%;
  margin-bottom: $theme-spacing-2;
}
.IntReportTable {
  min-width: 750px;
}

.ExcelReaderButtonFooterButton {
  width: 120px;
  float: right;
  margin: 0px 5px;
}

.TaskLog {
  width: 100%;
}

.TaskLogPaper {
  width: 100%;
  margin-bottom: $theme-spacing-2;
}

.TaskLogTable {
  min-width: 750px;
}

.TaskLogShineRow {
  &:hover {
    background-color: #DDDDDD !important;
  }
  background: #3dabe248;
}

.TaskLogWarnRow {
  &:hover {
    background-color: #DDDDDD !important;
  }
  background: #FFD0D0;
}

.TaskLogCalmRow {
  &:hover {
    background-color: #DDDDDD !important;
  }
}

.TaskLogVisuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
}

.TaskLogSearchInput {
  margin-right: $theme-spacing-1;
}

.TaskLogBackdrop {
  z-index: $theme-zIndex-drawer + 1;
  color: #fff;
}

.TaskLogTitle {
  flex: 1 1 100%;
}

.TaskLogDialog {
  width: 100%;
}

.TaskLogDialogHeading {
  font-size: 15;
  font-weight: $theme-typography-fontWeightRegular;
}

.Account {
  padding: $theme-spacing-4;
}


.toolBar {
  padding-left: $theme-spacing-2;
  padding-right: $theme-spacing-1;
}

.toolBarTitle {
  flex: 1 1 100%;
}

.toolBarSearchInput {
  margin-right: $theme-spacing-1;
}

.toolBarFocusPaper {
  border-radius: 4px;
  align-items: center;
  // padding: $theme-spacing-1;
  display: flex;
}

.UserManager {
  margin: 30px;
}

.UserManagerPaper {
  width: 100%;
  margin-bottom: $theme-spacing-2;
}

.UserManagerTable {
  min-width: 750px;
}

.UserManagerVisuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
}
