$breakpoints: (
  'xs': 600px,
  'sm': 600px,
  'md': 960px,
  'lg': 1280px,
  'xl': 1920px
);

$colors-blueGrey-800: #37474f;
$theme-palette-icon: #37474f; // rgba(0, 0, 0, 0.54);
$theme-palette-primary-main: #1976d2; // 主色
$theme-palette-white: #ffffff;

$theme-spacing-1: 8px;
$theme-spacing-2: 16px;
$theme-spacing-4: 32px;
$theme-spacing-8: 64px;
$theme-spacing-10: 80px;

$theme-typography-fontWeightMedium: 500;
$theme-typography-fontWeightRegular: 400;
$theme-zIndex-drawer: 1200; // 你的具体值可能不同
