@import './mui-variable.scss';

// 定义媒体查询
@mixin media($size) {
  @media (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}
// 使用示例
// .my-element {
//   width: 100%;

//   @include media('sm') {
//     width: 75%;
//   }

//   @include media('md') {
//     width: 50%;
//   }

//   @include media('lg') {
//     width: 33.33%;
//   }

//   @include media('xl') {
//     width: 25%;
//   }
// }

.AppBar {
  box-shadow: none;
}

.Minimal {
  padding-top: 64;
  height: '100%'
}

.MinimalContent {
  height: '100%'
}



.MainSidebarProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: fit-content;
}

.MainSidebarProfileAvatar {
  width: 60px !important;
  height: 60px !important;
}

.MainSidebarProfileName {
  margin-top: $theme-spacing-1;
  font-size: 20px;
}

.MainSidebarProfileMail {
  color: #546e7a;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.04px;
}
// .MainSidebarSidebarNav {
//   // 根样式，根据需要添加样式
// }

.MainSidebarSidebarNavItem {
  display: flex;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MainSidebarSidebarNavButton {
  color: $colors-blueGrey-800 !important;
  padding: 10px 8px !important;
  justify-content: flex-start !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  width: 100% !important;
  font-weight: $theme-typography-fontWeightMedium !important;
}

.MainSidebarSidebarNavIcon {
  color: $theme-palette-icon;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-right: $theme-spacing-1;
}

.MainSidebarSidebarNavActive {
  color: $theme-palette-primary-main;
  font-weight: $theme-typography-fontWeightMedium;

  & .icon {
    color: $theme-palette-primary-main;
  }
}


.MainSidebarSidebar {
  background-color: $theme-palette-white; // 假设$theme-palette-white是SCSS中的变量
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $theme-spacing-2; // 假设$theme-spacing-2是SCSS中的变量
}

.MainSidebarSidebarDrawer {
  width: 240px;

  @include media('lg') {
    margin-top: 64px;
    height: calc(100% - 64px);
  }
}

.MainSidebarSidebarDivider {
  margin: $theme-spacing-2 0 !important; // 假设$theme-spacing-2是SCSS中的变量
}

.MainSidebarSidebarNav {
  margin-bottom: $theme-spacing-2; // 假设$theme-spacing-2是SCSS中的变量
}

.MainToolbar {
  box-shadow: none;
}

.MainToolbarFlexGrow {
  flex-grow: 1;
}

.MainToolbarSignOutButton {
  margin-left: $theme-spacing-1; // 假设$theme-spacing-1是SCSS中的变量
}

.Main {
  padding-top: $theme-spacing-8; // 假设$theme-spacing-8是SCSS中的变量
  height: 100%;

  @include media('sm') {
    padding-top: $theme-spacing-10; // 假设$theme-spacing-10是SCSS中的变量
  }
}

.MainShiftContent {
  padding-left: 0; // 原来是 240px，如果你想使用 240px，取消注释这一行，注释掉下一行
  // padding-left: 240px;
}

.MainContent {
  height: 100%;
}

