input {
    color: black !important;
}
textarea {
    color: black !important;
}

/* input, filled-input, outlined-input */
.MuiInput-input, .MuiFilledInput-input, .MuiOutlinedInput-input, .MuiInputBase-input {
    padding: 12px 10px !important;
}

.MuiInput-inputMultiline, .MuiFilledInput-inputMultiline, .MuiOutlinedInput-inputMultiline, .MuiInputBase-inputMultiline {
    padding: 0 !important;
    min-height: 47px;
}

.MuiSelect-select.MuiSelect-select {
    color:black !important
}

.React-confirm-alert-cust-overlay {
    position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1400;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
.squeeze-text-field-area {
    min-height: 0px;
}